
.plot_header{
  background-color: #C7DE30;
  width: auto;
  height: auto;
  padding: 4px 0;
  text-align: right;
}

.modal_plot{
  text-align: center;
  float: right;
  flex:1;

}

.navbar{
  height: 12%;
  display: flex;
  align-items:center;
}

nav{
  flex:1;
  text-align: right;
}

nav ul li {
  list-style: none; 
  display: inline-block;
  margin-right: 60px;
}

nav ul a {
  text-decoration: none;
  color: #212226;
  font-size: 30px;
}

Table td{
  color:#212226;
}
Table th{
  color:#212226;
}
Table tr{
  color:#212226;
}

row-link {
  color:#212226;
}

.resizer {
  display: inline-block;
  background: #212226;
  width: 3px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  /* prevent scroll on touch devices */
  touch-action: none;
}

.btn-custom {
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.isResizing {
  background: blue;
}

.crossed {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 4px,
    transparent 1px,
    grey 7px
  ),
  linear-gradient(
    to bottom,
    transparent,
    transparent
  )
}

@font-face {
  font-family: 'DMMono';
  src: local('DMMono'), url(./fonts/DMMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Roboto'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}


@tailwind base;
@tailwind components;
@tailwind utilities;